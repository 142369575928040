let config={
    appName:'ThisFuns',
    googlePlayUrl:'https://play.google.com/store/apps/details?id=com.gamefactory.idleinvestor',
    appStoreUrl:'https://apps.apple.com/us/app/id1538306973',
    contactEmail:'thisfuns.cs@outlook.com',
    copyright:'ThisFuns',
    title:'Thisfun',
    logoName:'ThisFuns',
    fbid:'1124673794769586',
    websiteUrl:'thisfuns.com'
}

export default config;
